.footer {
  background-color: var(--mainBgColor);
  height: 3rem;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  align-content: center;
}

.ws-made-by {
  color: #872222;
  font-size: x-small;
}

.made-by {
  font-size: x-small;
  display: flex;
  gap: 2vw;
}
