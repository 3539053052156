.hero-instagram--a {
  gap: 1vw;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.how-to-book--p {
  margin: 0;
}

.slider-wrapper {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: center;
  gap: 1vw;
}

.slider-arrows {
  font-size: xx-large;
  cursor: pointer;
}

.slider-arrows:active {
  cursor: grabbing;
}
.slider-img {
  width: 75%;
  max-height: 50vh;
  object-fit: cover;
}

@media screen and (max-width: 600px) {
  .hero-instagram--a {
    flex-direction: column;
  }
}
