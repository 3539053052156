@import url("https://fonts.googleapis.com/css?family=Open+Sans:regular,bold,italic&subset=latin,latin-ext");

:root {
  --mainTextColor-light: #000;
  --secondaryTextColor-light: rgb(51 51 51);
  --mainLinkColor-light: #872222;
  --mainBorderColor-light: rgb(218, 218, 218);
  --mainBgColor-light: rgb(230, 228, 228);
  --secondaryBgColor-light: rgb(255, 255, 255);

  --mainTextColor-dark: #fff;
  --secondaryTextColor-dark: #c2a818;
  --mainLinkColor-dark: #fff;
  --mainBorderColor-dark: #2b3031;
  --mainBgColor-dark: #131415;
  --secondaryBgColor-dark: rgb(230, 228, 228);

  --mainTextColor: var(--mainTextColor-dark);
  --secondaryTextColor: var(--secondaryTextColor-dark);
  --mainLinkColor: var(--mainLinkColor-dark);
  --mainBorderColor: var(--mainBorderColor-dark);
  --mainBgColor: var(--mainBgColor-dark);
  --secondaryBgColor: var(--secondaryBgColor-dark);

  --font: Open Sans;
}

* {
  font-family: var(--font);
  /* line-height: 1.5rem; */
  box-sizing: border-box;
  color: var(--mainTextColor);
}

p,
span,
li {
  color: var(--mainTextColor);
  font-size: xx-large;
}

a,
h1,
h2,
h3 {
  text-decoration: none;
  color: var(--mainLinkColor);
  font-size: xx-large;
  font-weight: 900;
  text-align: center;
}

button {
  cursor: pointer;
}

button:active {
  cursor: grabbing;
}

button:hover {
  color: var(--secondaryTextColor);
  font-size: x-large;
  transition: 0.5s;
}

.divider {
  height: 1px;
  width: 75%;
  border-style: solid;
  background: var(--mainLinkColor);
  border: 0;
  background-image: -webkit-linear-gradient(
    left,
    #ccc,
    var(--mainLinkColor),
    #ccc
  );
  background-image: -moz-linear-gradient(
    left,
    #ccc,
    var(--mainLinkColor),
    #ccc
  );
  background-image: -ms-linear-gradient(left, #ccc, var(--mainLinkColor), #ccc);
  background-image: -o-linear-gradient(left, #ccc, var(--mainLinkColor), #ccc);
}

.ws-btn {
  font-size: small;
  font-family: Open Sans;
}

.header {
  padding: 10px 16px;
  background: var(--mainBgColor);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
  display: flex;
  justify-content: space-around;
  column-gap: 1em;
  flex-wrap: wrap;
}

.header-link {
  font-size: xx-large;
  padding: 2px;
  margin: 4px 20px 2px 20px;
}

.logo {
  width: 300px;
}

.content {
  /* padding: 16px; */
  padding-top: 100px;
  background-color: var(--mainBgColor);
}

.hero--wrapper {
  align-items: center;
  gap: 1em;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.hero-instagram {
  width: 20px;
}

.hero-img {
  width: 100%;
  height: auto;
  max-height: 1080px;
  opacity: 0.5;
  border-radius: 10px;
  object-fit: cover;
}

.hero-text {
  position: absolute;
  font-size: 2vw;
  color: var(--mainTextColor);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.hero-h3 {
  font-size: 3vw;
  color: var(--mainTextColor);
  display: flex;
  line-height: 6vw;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ws-hero-h3 {
  color: var(--secondaryTextColor);
  font-size: 9vw;
  line-height: 10vw;
}

.booking-btn {
  margin: 10px;
  border: 0;
  font-size: xx-large;
  background: transparent;
}

.quote-btn {
  margin: 10px;
  border: 0;
  font-size: xx-large;
  background: transparent;
}

.phone-btn {
  margin: 10px;
  border: 0;
  font-size: xx-large;
  background: transparent;
}

.van1-img {
  width: 100%;
  height: auto;
  max-height: 1080px;
  border-radius: 10px;
  object-fit: cover;
}

.marked-text {
  color: var(--mainLinkColor);
  font-weight: 900;
  font-size: 40px;
}

.our-vehicles--wrapper {
  align-items: center;
  gap: 1em;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}

.our-vehicles--p-img {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: stretch;
  gap: 50px;
  flex-wrap: wrap;
  flex-direction: row;
}

.our-vehicles--p {
  text-align: end;
  max-width: 50%;
}

.van2-img {
  width: 100%;
  height: auto;
  max-height: 1080px;
  border-radius: 10px;
  object-fit: cover;
}

.card {
  padding: 20px;
  box-shadow: 0px 0px 20px 0px #605f5f;
  margin: 5px 5px -30px 5px;
  border-radius: 10px;
  background-color: var(--mainBgColor);
  font-size: 25px;
  text-align: center;
}

@media screen and (max-width: 600px) {
  p {
    text-align: center;
  }

  .logo {
    width: 250px;
  }

  .content {
    padding: 16px;
    padding-top: 140px;
  }
}
